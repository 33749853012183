export default {
  title: "Mandat spécial de représentation auprès d'Enedis",
  subtitle:
    "Partagez les données du compteur Linky ; sous réserve de disponibilité :",
  "paragraph-1":
    "En cliquant sur le bouton « Valider », le Mandant – l’internaute et utilisateur du présent site internet – autorise expressément le Mandataire – la société élance Technologies Propres S.A.S, domiciliée 31 Avenue de Ségur, 75007 Paris, immatriculée au registre de commerce de Paris sous le numéro 878 632 306 00014, au capital social de 15,000 €, opérant sous la marque déposée de « projetsolaire ».",
  "paragraph-2":
    "à demander et à recevoir communication auprès du gestionnaire du réseau électrique Enedis – SA à directoire et à conseil de surveillance, au capital de 270 037 000 euros, immatriculée au R.C.S. de Nanterre sous le numéro 444 608 442 et dont le siège social est situé Tour Enedis, 34 Place des Corolles, 92070 Paris La Défense Cedex.",
  "paragraph-3":
    "des données cochées ci-dessous, sous réserve de disponibilité :",
  "paragraph-4":
    "Usage des données : Étude technico-financière pour l’installation d’un système photovoltaïque.",
  "paragraph-5":
    "La présente autorisation ne peut être cédée. Elle est consentie pour une durée de vingt-quatre (24) mois à compter de la date de signature (1 mois en l’absence de mention). Le Mandant accepte expressément que ses données personnelles soient conservées par le Mandataire à des fins de gestion et de traçabilité (5 ans à minima conformément à l'article 2224 du Code Civil). Conformément à la loi Informatique et Libertés du 6 janvier 1978, le Mandant dispose d’un droit d’accès, de rectification, de suppression et d’opposition pour motifs légitimes sur l’ensemble des données le concernant qu’il peut exercer sur simple demande auprès du Mandataire et/ou d’Enedis.",
  "checkbox-1": {
    title:
      "L’historique des mesures, en kWh, du site (et puissances atteintes et dépassements de puissance);",
    description:
      "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
  },
  "checkbox-2": {
    title: "L’historique des relevés d’index quotidiens, en kWh, du site;",
    description:
      "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
  },
  "checkbox-3": {
    title:
      "L’historique la puissance maximale quotidienne, en kVA ou kWh, du site ;",
    description:
      "sur la période souhaitée, de 36 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
  },
  "checkbox-4": {
    title:
      "L’historique de courbe de charge, aux pas restitués par Enedis, du site ;",
    description:
      "sur la période souhaitée, de 24 mois maximum à compter de la date de la demande (période limitée à la date de début du contrat)",
  },
  "checkbox-5": {
    title: "Les données techniques et contractuelles disponibles du site;",
    description:
      " Caractéristiques du raccordement, du dispositif de comptage et des informations contractuelles (option tarifaire, puissance souscrite…)",
  },
  "datasharing-disclaimer": "*Inclut métadonnées du mandant",
}
